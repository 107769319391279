/* eslint-disable no-unused-expressions */
export const loadChat = () => {
  const s1 = document.createElement('script');
  const s0 = document.getElementsByTagName('script')[0];
  s1.id = 'ze-snippet';
  s1.async = true;
  s1.src =
    'https://static.zdassets.com/ekr/snippet.js?key=ee406069-a858-4c8e-873e-270d8ea36eb2';
  s1.charset = 'UTF-8';
  s1.setAttribute('crossorigin', '*');
  s0.parentNode.insertBefore(s1, s0);
};

export const openChat = auth => {
  const zeSnippet = document.getElementById('ze-snippet');
  if (!auth) {
    return;
  }
  if (process.env.IS_PRODUCTION !== 'true') return;
  if (!zeSnippet) loadChat();
};

export const closeChat = () => {
  if (process.env.IS_PRODUCTION !== 'true') return;
  const zeSnippet = document.getElementById('ze-snippet');
  if (zeSnippet) {
    document.location.reload(true);
  }
};
