import Eev from 'eev';

export const events = {
  APPLICATION: {
    LOGOUT: 'APPLICATION/LOGOUT',
    CHANGE_LANGUAGE: 'APPLICATION/CHANGE_LANGUAGE',
    CHANGE_PROJECT: 'APPLICATION/CHANGE_PROJECT',
    CHANGE_IS_MOBILE: 'APPLICATION/CHANGE_IS_MOBILE',
    CHANGE_MODULE: 'APPLICATION/CHANGE_MODULE',
    CHANGE_BREADCRUMB: 'APPLICATION/CHANGE_BREADCRUMB',
  },
  MENU: {
    TOGGLE_COLLAPSE: 'MENU/TOGGLE_COLLAPSE',
    CHANGE_MENU_DATA: 'MENU/CHANGE_MENU_DATA',
  },
  USER: {
    UPDATE_CONFIG: 'USER/UPDATE_CONFIG',
  },
};

const eev = new Eev();
if (process.env.NODE_ENV !== 'production') {
  const superEmit = eev.emit;
  eev.emit = (...args) => {
    const [event, ...params] = args;
    console.log(`EVENT: ${event}`, params); // eslint-disable-line
    superEmit.apply(eev, args);
  };
}

export { eev };
