import { start } from 'single-spa';
import { init as sentryInit } from '@sentry/browser';
import { hotjar } from 'react-hotjar';
import loadApp from './helper';
import './bootstrap';
import './events/subscriptions';
import './scripts/ga';

if (process.env.NODE_ENV !== 'development') {
  sentryInit({ dsn: process.env.SENTRY_URL });
  hotjar.initialize(process.env.HOTJAR_ID, 6);
}

const init = () => {
  if (
    !window.location.pathname.startsWith('/core') &&
    !window.location.pathname.startsWith('/assembly') &&
    !window.location.pathname.startsWith('/contracts') &&
    !window.location.pathname.startsWith('/vivenda')
  )
    window.history.replaceState(null, null, '/assembly');

  loadApp('assembly', `${process.env.ASSEMBLY_URL}/singleSpa.js`, location =>
    location.pathname.startsWith('/assembly')
  );

  loadApp('core', `${process.env.CORE_URL}/singleSpa.js`, location =>
    location.pathname.startsWith('/core')
  );

  loadApp('contracts', `${process.env.CONTRACTS_URL}/singleSpa.js`, location =>
    location.pathname.startsWith('/contracts')
  );

  loadApp('vivenda', `${process.env.VIVENDA_URL}/singleSpa.js`, location =>
    location.pathname.startsWith('/vivenda')
  );

  start();
};

init();
