import t from './utils/i18n';

export const getMenuByPermission = array => {
  const permissions = window.mssp.auth.user.permissions.map(item => item.name);

  const newMenu = [];
  array.forEach(menuItem => {
    const item = { ...menuItem };
    if (!item.permissions) return newMenu.push(item);
    if (!item.permissions.length) return null;
    if (item.permissions.some(per => permissions.includes(per))) {
      if (item.children) {
        item.children = getMenuByPermission(item.children);
      }
      newMenu.push(item);
    }
    return null;
  });
  return newMenu;
};

export default () => [
  {
    path: '/assembly/',
    name: t('HOME'),
    icon: 'home',
  },
  {
    path: '/assembly/dashboard',
    name: t('DASHBOARD'),
    icon: 'dashboard',
    permissions: ['access_dashboard'],
    children: [
      {
        path: '/assembly/dashboard/overview',
        name: t('OVERVIEW'),
        permissions: ['access_dashboard_category_overview'],
      },
      {
        path: '/assembly/dashboard/programming',
        name: t('PROGRAMMING'),
        permissions: ['access_dashboard_category_programming'],
      },
      {
        path: '/assembly/dashboard/planning',
        name: t('PLANNING'),
        permissions: ['access_dashboard_category_planning'],
      },
      {
        path: '/assembly/dashboard/contracts',
        name: t('CONTRACTS'),
        permissions: ['access_dashboard_category_contracts'],
      },
      {
        path: '/assembly/dashboard/quantitatives',
        name: t('QUANTITATIVES'),
        permissions: ['access_dashboard_category_quantitatives'],
      },
      {
        path: '/assembly/dashboard/budget',
        name: t('BUDGET'),
        permissions: ['access_dashboard_category_budget'],
      },
    ],
  },
  {
    path: '/assembly/programming',
    name: t('PROGRAMMING'),
    icon: 'calendar',
    permissions: ['access_program_orders'],
  },
  {
    path: '/contracts/contracts',
    name: t('CONTRACTS'),
    icon: 'container',
    permissions: ['access_contracts'],
  },
  {
    path: '/contracts/payment_reports',
    name: t('PAYMENT_REPORTS'),
    icon: 'dollar',
    permissions: ['access_payment'],
  },
  {
    path: '/assembly/reports',
    name: t('REPORTS'),
    icon: 'file-text',
    permissions: ['access_report_assembly'],
  },
  {
    path: '/assembly/register',
    name: t('REGISTER'),
    icon: 'form',
    permissions: [
      'access_user',
      'activity_access',
      'access_project_local',
      'access_supply_group',
    ],
    children: [
      {
        path: '/assembly/register/setup',
        name: 'Setup',
        permissions: [
          'activity_access',
          'access_project_local',
          'access_activity_instance',
        ],
      },
      {
        path: '/core/register/users',
        name: t('USERS'),
        permissions: ['access_user'],
      },
      {
        path: '/core/register/contractors',
        name: t('CONTRACTORS'),
        permissions: ['access_contractor'],
      },
      {
        path: '/vivenda/benefitiaries',
        name: t('BENEFITIARIES'),
        permissions: ['access_beneficiary'],
      },
      {
        path: '/vivenda/financiers',
        name: t('FINANCIERS'),
        permissions: ['access_financiers'],
      },
      {
        path: '/vivenda/cycles',
        name: t('CYCLES'),
        permissions: ['access_cycles'],
      },
      {
        path: '/vivenda/accountabilities',
        name: t('ACCOUNTABILITIES'),
        permissions: ['access_accountabilities'],
      },
      {
        path: '/vivenda/executors',
        name: t('EXECUTORS'),
        permissions: ['access_executors'],
      },
      {
        path: '/vivenda/projects',
        name: t('PROJECTS'),
        permissions: ['access_projects'],
      },
    ],
  },
];
