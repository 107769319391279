import { navigateToUrl } from 'single-spa';
import { LOCALSTORAGE_KEY } from '../bootstrap';
import getMenuData, { getMenuByPermission } from '../menu';
import { eev, events } from '.';
import { openChat, closeChat } from '../chat';

eev.on(events.MENU.TOGGLE_COLLAPSE, collapsed => {
  global.mssp.menu.collapsed = collapsed;
});

eev.on(events.APPLICATION.CHANGE_LANGUAGE, language => {
  global.mssp.application.language = language;
  if (global.mssp.auth) {
    const menuData = getMenuByPermission(getMenuData());
    global.mssp.menu.menuData = menuData;
    eev.emit(events.MENU.CHANGE_MENU_DATA, menuData);
  }
});

eev.on(events.APPLICATION.CHANGE_PROJECT, project => {
  global.mssp.application.project = project;
});

eev.on(events.USER.UPDATE_CONFIG, auth => {
  const { mssp } = window;
  if (!auth) {
    localStorage.removeItem(LOCALSTORAGE_KEY);
    return;
  }

  const {
    user: { config },
  } = auth;

  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(auth));
  mssp.auth = auth;

  if (mssp.application.project !== config.project_id) {
    mssp.eev.emit(
      events.APPLICATION.CHANGE_PROJECT,
      auth.user.config.project_id
    );
  }
  mssp.auth.user.config.currency_iso_code =
    mssp.auth.user.projects.find(
      project => project.id === mssp.application.project
    ).currency_iso_code || 'BRL';
  mssp.eev.emit(
    events.APPLICATION.CHANGE_LANGUAGE,
    auth.user.config.language || 'ptbr'
  );
  mssp.application.initialSetupDone = true;
  openChat(auth);
});

eev.on(events.APPLICATION.LOGOUT, () => {
  localStorage.removeItem(LOCALSTORAGE_KEY);
  global.mssp.auth = null;
  closeChat();
  navigateToUrl('/assembly/login');
});

eev.on(events.APPLICATION.CHANGE_BREADCRUMB, breadcrumb => {
  global.mssp.application.breadcrumb = breadcrumb;
});
