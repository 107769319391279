import { navigateToUrl } from 'single-spa';
import headers from './utils/headers';
import * as events from './events';

// eslint-disable-next-line
export const LOCALSTORAGE_KEY = '@MSSP:auth';

const auth = localStorage[LOCALSTORAGE_KEY]
  ? JSON.parse(localStorage[LOCALSTORAGE_KEY])
  : null;

const mssp = {
  navigate: navigateToUrl,
  services: {
    changePassword: `${process.env.API_URL}/core/1/users/update_password`,
    loadConfig: `${process.env.API_URL}/core/1/session/config`,
    saveConfig: `${process.env.API_URL}/core/1/users/config`,
    logout: `${process.env.API_URL}/core/1/session/logout`,
  },
  auth,
  menu: {
    collapsed: true,
  },
  application: {
    headers,
    isMobile: false,
    language: 'ptbr',
    module: 'assembly',
    project: auth ? auth.user.config.project_id : undefined,
    initialSetupDone: false,
    breadcrumb: [],
  },
  ...events,
};

global.SystemJS = global.System;
global.mssp = mssp;
